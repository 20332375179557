<template>
  <!-- BEGIN: Content-->
  <div class="body-login">
    <div class="content-overlay" />
    <div class="content-wrapper">
      <div class="content-header row" />
      <div class="content-body">
        <div class="auth-wrapper auth-cover">
          <div class="auth-inner row m-0">
            <!-- Brand logo-->
            <a
              class="brand-logo"
              href="https://www.icrea.cat"
            />

            <!-- /Brand logo-->
            <!-- Left Text-->
            <div
              class="d-none d-lg-flex col-lg-8 align-items-center p-5"
              :style="{'background': 'url(' + require('@/assets/images/illustration/bg-login.svg') + ') center center no-repeat', 'background-size': 'cover'}"
            >
              <div class="w-100 d-lg-flex align-items-center justify-content-center flex-column px-5">
                <div class="mb-4">
                  <img
                    class="img-fluid"
                    :src="require('@/assets/images/logo/logo-icrea-white-login.svg')"
                    alt="ICREA"
                  >
                </div>
                <!-- <div class="quote">
                  <h1><em>"You never fail until you stop trying"</em></h1>
                  <hr>
                  <p>Albert Einstein</p>
                </div> -->
              </div>
            </div>
            <!-- /Left Text-->
            <!-- Login-->
            <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
              <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title fw-bold mb-1">
                  Generate a new password
                </h2>
                <p class="card-text mb-2">
                  Enter a new password
                </p>
                <form
                  class="auth-login-form mt-2"
                >
                  <div class="mb-1">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="login-password"
                      >New Password</label>
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                      <input
                        id="new-password"
                        v-model="newPassword"
                        class="form-control form-control-merge"
                        :type="type"
                        name="new-password"
                        placeholder="············"
                        aria-describedby="login-password"
                        tabindex="5"
                      ><span
                        class="input-group-text cursor-pointer"
                        @click="showPassword('new')"
                      ><i data-feather="eye" /></span>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="login-password"
                      >Confirm Password</label>
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                      <input
                        id="confirm-password"
                        v-model="confirmPassword"
                        class="form-control form-control-merge"
                        :type="type2"
                        name="new-password"
                        placeholder="············"
                        aria-describedby="login-password"
                        tabindex="5"
                      ><span
                        class="input-group-text cursor-pointer"
                        @click="showPassword('confirm')"
                      ><i data-feather="eye" /></span>
                    </div>
                  </div>

                  <!-- <button class="btn btn-primary w-100" tabindex="4">Generate and send new password</button> -->
                  <a
                    v-if="!loading"
                    href="#"
                    :class="{ 'disabled': !confirmedPassword }"
                    class="btn btn-primary w-100"
                    tabindex="4"
                    @click="changePassword"
                  >Save new password</a>
                  <b-button
                    v-else
                    type="submit"
                    disabled
                  >
                    <div class="loading">
                      <div class="effect-1 effects" />
                      <div class="effect-2 effects" />
                      <div class="effect-3 effects" />
                    </div>
                  </b-button>
                </form>
              </div>
            </div>
            <!-- /Login-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      loading: false,
      type: 'password',
      type2: 'password',
      confirmedPassword: false,
      encryptedEmail: this.$route.query.payload,
    }
  },
  watch: {
    newPassword() {
      if (this.newPassword.length > 0 && this.confirmPassword === this.newPassword) {
        this.confirmedPassword = true
      } else {
        this.confirmedPassword = false
      }
    },
    confirmPassword() {
      if (this.newPassword.length > 0 && this.confirmPassword === this.newPassword) {
        this.confirmedPassword = true
      } else {
        this.confirmedPassword = false
      }
    },
  },
  methods: {
    changePassword() {
      this.loading = true
      if (this.newPassword.length > 0) {
        this.$store.dispatch('auth/changePassword', { email: this.encryptedEmail, password: this.newPassword }).then(() => {
          this.$router.push({ name: 'reset-password-ok' })
        }).catch(exception => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: exception,
              icon: 'ErrorIcon',
              variant: 'error',
            },
          })
        })
      } else {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Password is required',
            icon: 'ErrorIcon',
            variant: 'error',
          },
        })
      }
    },
    showPassword(passwordType) {
      switch (passwordType) {
        case 'new':
          if (this.type === 'password') {
            this.type = 'text'
          } else {
            this.type = 'password'
          }
          break
        case 'confirm':
          if (this.type2 === 'password') {
            this.type2 = 'text'
          } else {
            this.type2 = 'password'
          }
          break
        default:
          break
      }
    },
  },
}
</script>

<style>

</style>
